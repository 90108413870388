import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput, 
    useInput, required, ImageInput, ImageField,
    useRecordContext,
    useListContext,
    useDataProvider,
    DateTimeInput,
    useEditContext,
} from 'react-admin';
import { Divider, Box, Grid, Typography, IconButton  } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export const TaskInputs = ({selectedManager, handleManagerChange}) => {
    const editContext = useEditContext();
    const navigate = useNavigate();

    return (<Box flex="1" mt={-1}>

		<Box display="flex" flexDirection="row">
			<IconButton
				  onClick={() => navigate(-1)}
				  size="small"
				>
				  <KeyboardBackspaceIcon />
			</IconButton>
            { editContext.record ? <h3>Редактирование задачи</h3> :  <h3>Новая задача (без привязки к лиду)</h3>}
		</Box>

        <TextInput
          label="Введите текст задачи"
          fullWidth
          //multiline
          source="comment"
          //rows={2}
          validate={required()}
        />
        
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
            <ReferenceInput source="task_manager_id" reference="sales" filter={{ 'sales_id_for_tasks': localStorage.getItem('id') }} >
                <AutocompleteInput label="Менеджер" onChange={handleManagerChange}  defaultValue={selectedManager.id} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} fullWidth validate={required()} />
            </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={4}>
               <ReferenceInput source="task_type" reference="taskTypes" >
                    <SelectInput label="Тип задачи" optionText="name" optionValue="name" fullWidth validate={required()} />
                </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box display="flex" flexDirection="row" gap={2} flexWrap="wrap">
                    <Box flex={1}>
                        <DateTimeInput label="Назначить на" source="date_time" validate={required()} fullWidth />
                    </Box>
                    <Box flex={1}>
                        <BooleanInput source="important" label="Важная задача" fullWidth />
                    </Box>
                </Box>
            </Grid>
        </Grid>


    </Box>);
};
