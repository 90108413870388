import React, { useState, useEffect } from 'react';
import { endpoint, wsEndpoint } from '../App';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
    SimpleList,
    FilterLiveSearch,
    FunctionField,
    Loading,
    DateInput,
    TimeInput,
    DateTimeInput,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
    Form
} from 'react-admin';
import {
    Box, 
    useMediaQuery,
    List,
    ListItem,
    ListItemText,
    Typography,
    Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import moment from 'moment';
moment.locale('ru');

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: accounts,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

  const handleClick = () => {

    const filteredDataToExport = accounts.map(({ avatar, password, connectedToLeads, picture, ...rest }) => rest);

    const ws = XLSX.utils.json_to_sheet(filteredDataToExport);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
    XLSX.writeFile(wb, "Аналитика - Выгрузка из CRM.xlsx");
  };

  return (
    <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />}>
      Выгрузить в Excel
    </Button>
  );
};

function getStartDefault() {
    return moment().set({ hour: 8, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DDTHH:mm:ss');
}

function getEndDefault() {
    return moment().set({ hour: 20, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DDTHH:mm:ss');
}

function getEndOfMonthDate() {
    const endDate = moment.utc(new Date()).endOf('month').toDate();
    return endDate;
}

const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // Assuming YYYY-MM-DD format for the DateInput
};

const formatTime = (isoString) => {
    const date = new Date(isoString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};

const formatBroadcastTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

const formatTotalBroadcastTime = (seconds) => {
    const totalHours = seconds / 3600;
    const formattedHours = Number.isInteger(totalHours) ? totalHours : totalHours.toFixed(1);
    return `${formattedHours} ч.`;
}

const requestIncomeSourcesData = (text) => {
    const lines = text.split('\n');

    return (
        <Typography variant="body2">
            {lines.map((line, index) => (
                <span key={index}>
                    {line}
                    <br />
                </span>
            ))}
        </Typography>
    );
}

/*const formatDealsData = (deals) => {
  return(
    deals.map(deal => (
        <Box display="flex" flexDirection="column" width="100%">
        <Typography variant="body2">{new Date(deal.deal_confirmed).toLocaleDateString('RU-ru')}: </Typography>
        <Link to={`/deals/${deal.id}`} target="_blank" >Сделка №{deal.document_name} - {deal.name}</Link>
        </Box>
    ))
  );
}*/
const formatDealsData = (deals) => {
    if (deals.length > 0) {
      return (
        <Accordion style={{ width: '100%' }}>
          {/* Заголовок с кнопкой "развернуть/свернуть" */}
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel-header"
          >
            <Typography variant="body2">
              Список сделок
            </Typography>
          </AccordionSummary>
  
          {/* Детали сделок */}
          <AccordionDetails>
            {deals.map(deal => (
              <Box key={deal.id} display="flex" flexDirection="column" width="100%" mb={1}>
                <Typography variant="body2">
                  {new Date(deal.deal_confirmed).toLocaleDateString('RU-ru')}: 
                </Typography>
                <Link to={`/deals/${deal.id}`} target="_blank">
                  Сделка №{deal.document_name} - {deal.name}
                </Link>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      );
    }
  
    return null;
};

// Custom filter component using DateInput
const AnalyticsFilter = ({ setFilters, defaultStartDate, defaultEndDate, currentManagerFilter, handleSelectedManagerFilterChange }) => {
    const handleApplyFilters = (values) => {
        if (values.startDate && values.endDate) {
            setFilters({ search_start_time: values.startDate, search_end_time: values.endDate });
        }
    };

    return (
        <Form className='filterForm' onSubmit={handleApplyFilters}>
            <Box display="flex" flexDirection="row" mb={1} mt={2} alignItems="center">
                <DateTimeInput
                    label="Начало периода"
                    source="startDate"
                    defaultValue={defaultStartDate}
                    style={{ marginRight: '1rem' }}
                />
                <DateTimeInput
                    label="Окончание периода"
                    source="endDate"
                    defaultValue={defaultEndDate}
                    style={{ marginRight: '1rem' }}
                />
                <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'head_of_dep': true }} >
                  <AutocompleteInput label="Руководитель" defaultValue={currentManagerFilter} onChange={handleSelectedManagerFilterChange} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} />
                </ReferenceInput>
                <Button type="submit" variant="contained" color="primary" style={{ marginLeft: '1rem' }}>
                    Показать за период
                </Button>
            </Box>
        </Form>
    );
};

const AnalyticsPage = () => {
    const { identity } = useGetIdentity();
    const [managers, setManagers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [numDays, setNumDays] = useState(1);
    const [daysTillEndOfMonth, setDaysTillEndOfMonth] = useState();
    const [filters, setFilters] = useState({
        search_start_time: getStartDefault(),
        search_end_time: getEndDefault() ,
    });
    const [currentManagerFilter, setCurrentManagerFilter] = useState(null);

    useEffect(() => {
        fetchData(filters);
    }, []);

    const fetchData = async (filters) => {
        setLoading(true);

        setNumDays(Math.ceil((new Date(filters.search_end_time) - new Date(filters.search_start_time)) / (1000 * 60 * 60 * 24)));
        setDaysTillEndOfMonth(Math.ceil((new Date(getEndOfMonthDate()) - new Date(filters.search_start_time)) / (1000 * 60 * 60 * 24)));

        try {
            const token = localStorage.getItem('token');
            const id = localStorage.getItem('id');
            const response = await axios.get(
                `${endpoint}/analytics`,
                {
                    params: {
                        manager_account_id: id,
                        team_members_head_id: currentManagerFilter,
                        ...filters
                    },
                    headers: {
                        Authorization: `${token}`
                    }
                }
            );

            setManagers(response.data.sort((a, b) => b.broadcast_time - a.broadcast_time));
            // бывшая сортировка по окончанию добавочного 
            //setManagers(response.data.sort((a, b) => a.phone_extension.slice(-2).localeCompare(b.phone_extension.slice(-2))));
        } catch (error) {
            console.error('Fetch failed:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSetFilters = (filters) => {
        fetchData(filters);
    };

    const handleSelectedManagerFilterChange = (value) => {
        setCurrentManagerFilter(value);
    };

     // Calculate totals
     const totalCalls = managers.reduce((sum, manager) => sum + manager.calls, 0);
     const totalBroadcastTime = managers.reduce((sum, manager) => sum +  manager.broadcast_time, 0);
     const totalScheduledMeetings = managers.reduce((sum, manager) => sum + manager.scheduled_meetings, 0);
     const totalOnlineMeetings = managers.reduce((sum, manager) => sum + manager.online_meetings, 0);
     const totalOfflineMeetings = managers.reduce((sum, manager) => sum + manager.offline_meetings, 0);
     const totalDeals = managers.reduce((sum, manager) => sum + manager.dealsTotal, 0);

     const callsPlan = numDays * 33 * managers.length;
     const broadcastPlan = numDays * 1.167 * 3600 * managers.length;
     const scheduledMeetingsPlan = numDays * 3 * managers.length;
     const onlineMeetingsPlan = numDays * 2 * managers.length;
     const offlineMeetingsPlan = numDays * 1 * managers.length;
     const dealsPlan = numDays * 1 * managers.length;   

    return identity ? (
        <Box>
            <AnalyticsFilter 
                setFilters={handleSetFilters} 
                defaultStartDate={filters.search_start_time} 
                defaultEndDate={filters.search_end_time}
                handleSelectedManagerFilterChange={handleSelectedManagerFilterChange}
                currentManagerFilter={currentManagerFilter} 
            />
            {loading || managers.length < 1 ? (
                <Loading />
            ) : (
                <List sx={{ backgroundColor: 'background.paper' }}>
                    <ListItem sx={{ padding: 0, alignItems: 'stretch', borderBottom: '1px solid lightgrey' }}>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Менеджер" />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Заявки" secondary={managers[0].requestsTotal.requestsTotalCount}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Звонки" secondary={`${totalCalls} / ${callsPlan}`}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Эфир" secondary={`${formatBroadcastTime(totalBroadcastTime)} / ${formatTotalBroadcastTime(broadcastPlan)}`}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Назначено встреч" secondary={`${totalScheduledMeetings} / ${scheduledMeetingsPlan}`}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Онлайн-встречи" secondary={`${totalOnlineMeetings} / ${onlineMeetingsPlan}`}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Оффлайн-встречи" secondary={`${totalOfflineMeetings} / ${offlineMeetingsPlan}`}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Сделки" secondary={`${totalDeals} / ${dealsPlan}`}/>
                        </Box>
                    </ListItem>
            {managers.map(manager => {
                    const callsPlanManager = numDays * 33;
                    const broadcastPlanManager = numDays * 1.167 * 3600;
                    const scheduledMeetingsPlanManager = numDays * 3;
                    const onlineMeetingsPlanManager = numDays * 2;
                    const offlineMeetingsPlanManager = numDays * 1;
                    const dealsPlanManager = numDays * 1;

                    return (
                        <ListItem key={manager.id} sx={{ padding: 0, alignItems: 'stretch', borderBottom: '1px solid lightgrey' }}>
                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                                <ListItemText 
                                    primary={`${manager.name}`} secondary={`${manager.phone_extension}`}
                                    primaryTypographyProps={{ 
                                        sx:{ fontWeight: manager.head_of_dep ? 'bold' : 'normal' }
                                    }}
                                />
                            </Box>

                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                                <ListItemText 
                                    primary={`${manager.requests.requestsCount}`} secondary={requestIncomeSourcesData(manager.requests.requestsSourcesString)}
                                />
                            </Box>

                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center">
                                <ListItemText 
                                    primary={manager.calls} 
                                    primaryTypographyProps={{ 
                                        sx:{ fontWeight: 'bold', color: manager.calls >= callsPlanManager ? '#01BF00' : 'red' }
                                    }}
                                    //secondary={`ДВПД:`}
                                />
                                </Box>
                                <Box display="flex" flex={3} justifyContent="start" alignItems="center">
                                <ListItemText 
                                    primary={`/ ${callsPlanManager}`} 
                                    primaryTypographyProps={{ 
                                        sx:{ fontWeight: manager.calls >= callsPlanManager ? 'bold' : 'normal', color: manager.calls >= callsPlanManager ? '#01BF00' : 'default' }
                                    }} 
                                    //secondary={Math.ceil(Math.abs((manager.calls - callsPlanManager) / daysTillEndOfMonth))}
                                />
                                </Box>
                            </Box>

                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center">
                                <ListItemText 
                                    primary={formatBroadcastTime(manager.broadcast_time)} 
                                    primaryTypographyProps={{ 
                                        sx:{ fontWeight: 'bold', color: manager.broadcast_time >= broadcastPlanManager ? '#01BF00' : 'red' }
                                    }}
                                />
                                </Box>
                                <Box display="flex" flex={3} justifyContent="start" alignItems="center">
                                <ListItemText 
                                    primary={`/ ${formatTotalBroadcastTime(broadcastPlanManager)}`} 
                                    primaryTypographyProps={{ 
                                        sx:{ fontWeight: manager.broadcast_time >= broadcastPlanManager ? 'bold' : 'normal', color: manager.broadcast_time >= broadcastPlanManager ? '#01BF00' : 'default' }
                                    }} 
                                />
                                </Box>
                            </Box>

                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center">
                                <ListItemText 
                                    primary={manager.scheduled_meetings} 
                                    primaryTypographyProps={{ 
                                        sx:{ fontWeight: 'bold', color: manager.scheduled_meetings >= scheduledMeetingsPlanManager ? '#01BF00' : 'red' }
                                    }}
                                />
                                </Box>
                                <Box display="flex" flex={3} justifyContent="start" alignItems="center">
                                <ListItemText 
                                    primary={`/ ${scheduledMeetingsPlanManager}`} 
                                    primaryTypographyProps={{ 
                                        sx:{ fontWeight: manager.scheduled_meetings >= scheduledMeetingsPlanManager ? 'bold' : 'normal', color: manager.scheduled_meetings >= scheduledMeetingsPlanManager ? '#01BF00' : 'default' }
                                    }} 
                                />
                                </Box>
                            </Box>
 
                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center">
                                <ListItemText 
                                    primary={manager.online_meetings} 
                                    primaryTypographyProps={{ 
                                        sx:{ fontWeight: 'bold', color: manager.online_meetings >= onlineMeetingsPlanManager ? '#01BF00' : 'red' }
                                    }}
                                />
                                </Box>
                                <Box display="flex" flex={3} justifyContent="start" alignItems="center">
                                <ListItemText 
                                    primary={`/ ${onlineMeetingsPlanManager}`} 
                                    primaryTypographyProps={{ 
                                        sx:{ fontWeight: manager.online_meetings >= onlineMeetingsPlanManager ? 'bold' : 'normal', color: manager.online_meetings >= onlineMeetingsPlanManager ? '#01BF00' : 'default' }
                                    }} 
                                />
                                </Box>
                            </Box>

                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center">
                                <ListItemText 
                                    primary={manager.offline_meetings} 
                                    primaryTypographyProps={{ 
                                        sx:{ fontWeight: 'bold', color: manager.offline_meetings >= offlineMeetingsPlanManager ? '#01BF00' : 'red' }
                                    }}
                                />
                                </Box>
                                <Box display="flex" flex={3} justifyContent="start" alignItems="center">
                                <ListItemText 
                                    primary={`/ ${offlineMeetingsPlanManager}`}
                                    primaryTypographyProps={{ 
                                        sx:{ fontWeight: manager.offline_meetings >= offlineMeetingsPlanManager ? 'bold' : 'normal', color: manager.offline_meetings >= offlineMeetingsPlanManager ? '#01BF00' : 'default' }
                                    }} 
                                />
                                </Box>
                            </Box>

                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                                <Box display="flex" flexDirection="column" width="100%">
                                <Box display="flex" flexDirection="row" width="100%">
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center">
                                <ListItemText 
                                    primary={manager.dealsTotal} 
                                    primaryTypographyProps={{ 
                                        sx:{ fontWeight: 'bold', color: manager.dealsTotal >= dealsPlanManager ? '#01BF00' : 'red' }
                                    }}
                                />
                                </Box>
                                <Box display="flex" flex={3} justifyContent="start" alignItems="center">
                                <ListItemText 
                                    primary={`/ ${dealsPlanManager}`} 
                                    primaryTypographyProps={{ 
                                        sx:{ fontWeight: manager.dealsTotal >= dealsPlanManager ? 'bold' : 'normal', color: manager.dealsTotal >= dealsPlanManager ? '#01BF00' : 'default' }
                                    }}
                                />
                                </Box>
                                </Box>
                                {formatDealsData(manager.deals)}
                                </Box>
                            </Box>
                        </ListItem>
                    );
                })}
                    <ListItem sx={{ padding: 0, alignItems: 'stretch', borderBottom: '1px solid lightgrey' }}>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Итого:" />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary={`Заявки: ${managers[0].requestsTotal.requestsTotalCount}`}  secondary={requestIncomeSourcesData(managers[0].requestsTotal.requestsTotalSourcesString)} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Звонки" primary={`${totalCalls} / ${callsPlan}`}
                            primaryTypographyProps={{ 
                                sx:{ fontWeight: totalCalls >= callsPlan ? 'bold' : 'normal', color: totalCalls >= callsPlan ? '#01BF00' : 'default' }
                            }}
                            />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Эфир" primary={`${formatBroadcastTime(totalBroadcastTime)} / ${formatTotalBroadcastTime(broadcastPlan)}`}
                            primaryTypographyProps={{ 
                                sx:{ fontWeight: totalBroadcastTime >= broadcastPlan ? 'bold' : 'normal', color: totalBroadcastTime >= broadcastPlan ? '#01BF00' : 'default' }
                            }}
                            />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Назначено встреч" primary={`${totalScheduledMeetings} / ${scheduledMeetingsPlan}`}
                            primaryTypographyProps={{ 
                                sx:{ fontWeight: totalScheduledMeetings >= scheduledMeetingsPlan ? 'bold' : 'normal', color: totalScheduledMeetings >= scheduledMeetingsPlan ? '#01BF00' : 'default' }
                            }}
                            />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Онлайн-встречи" primary={`${totalOnlineMeetings} / ${onlineMeetingsPlan}`}
                            primaryTypographyProps={{ 
                                sx:{ fontWeight: totalOnlineMeetings >= onlineMeetingsPlan ? 'bold' : 'normal', color: totalOnlineMeetings >= onlineMeetingsPlan ? '#01BF00' : 'default' }
                            }}
                            />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Оффлайн-встречи" primary={`${totalOfflineMeetings} / ${offlineMeetingsPlan}`}
                            primaryTypographyProps={{ 
                                sx:{ fontWeight: totalOfflineMeetings >= offlineMeetingsPlan ? 'bold' : 'normal', color: totalOfflineMeetings >= offlineMeetingsPlan ? '#01BF00' : 'default' }
                            }}
                            />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ marginRight: '1rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Сделки" primary={`${totalDeals} / ${dealsPlan}`} 
                             primaryTypographyProps={{ 
                                sx:{ fontWeight: totalDeals >= dealsPlan ? 'bold' : 'normal', color: totalDeals >= dealsPlan ? '#01BF00' : 'default' }
                            }}
                            /> 
                        </Box>
                    </ListItem>
                </List>
            )}
        </Box>
    ) : (
        <Loading />
    );
};

export default AnalyticsPage;
