import * as React from 'react';
import { ReferenceField, useRedirect } from 'react-admin';
import { Box, Card, Typography, Link } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import { FcExpired } from "react-icons/fc";
import { GetTaskIcon } from '../requestsHistory/types';

//import { typeChoices, getStatusColor } from './types';

export const TaskCard = ({ task, index, stageColor }) => {
    const redirect = useRedirect();
    if (!task) return null;

    const handleClick = () => {
        //redirect(task.ap_id === localStorage.getItem('id') ? `/requestsHistory/${task.id}` : `/requestsHistory/${task.id}/show`);
        redirect(task.request_id ? `/requests/${task.request_id}` : `/requestsHistory/${task.id}/show`);
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
        window.open(task.request_id ? `#/requests/${task.request_id}` : `#/requestsHistory/${task.id}/show`, '_blank');
    };

    const displayDateTime = () => {
        const taskDate = new Date(task.date_time);
        const currentDate = new Date();
       /* const todayDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
        );*/

        if (taskDate < currentDate) {
            return taskDate.toLocaleString('ru-RU');
        } else if (taskDate >= currentDate && taskDate < new Date(currentDate.getTime() + 24 * 60 * 60 * 1000)) {
            return taskDate.toLocaleTimeString('ru-RU');
        } else {
            return taskDate.toLocaleString('ru-RU');
        }
    };

    return (
        <Draggable draggableId={String(task.id)} index={index} isDragDisabled={true}>
            {(provided, snapshot) => (
                <Box
                    //sx={{ marginBottom: 1 }}
                    sx={{
                        marginBottom: 1,
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    onClick={handleClick}
                    onContextMenu={handleContextMenu}
                >
                    <Card
                        style={{
                            opacity: snapshot.isDragging ? 0.9 : 1,
                            transform: snapshot.isDragging
                                ? 'rotate(-2deg)'
                                : '',
                        }}
                        elevation={snapshot.isDragging ? 3 : 1}
                    >
<Box padding={1} display="flex">
    <Box
        sx={{
            //borderLeft: `4px solid ${getStatusColor(request.status)}`,
            padding: '0 16px',
            marginRight: '16px', 
        }}
    >
        {/*<Typography variant="body2" style={{ color: getStatusColor(request.status)}}>{request.status ? typeChoices.find(status => status.id === request.status).name : null}</Typography>*/}
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            {task.important && (
                <Box>
                    <FcExpired size={30} />
                </Box>
            )}
            <Box flex="1" display="flex" flexDirection="column">
                <Typography variant="caption" color="textSecondary">
                    {GetTaskIcon(task.task_type)} {task.task_type}
                </Typography>
                <Typography variant="body1" fontWeight="bold" gutterBottom>
                    {task.comment}
                </Typography>
            </Box>
        </Box>
        <Typography variant="body2">
            {task.contact_name}
        </Typography>
        <Typography variant="caption" fontWeight="bold" color={stageColor}>
            {displayDateTime()}
        </Typography>
        <br/>
        <Typography variant="caption" color="textSecondary">
            {task.manager_name}
        </Typography>
    </Box>
</Box>
                    </Card>
                </Box>)}
        </Draggable>
    );
};
