import * as React from 'react';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle, Fragment } from 'react';
import {
    CreateButton,
    ExportButton,
    FilterButton,
    List,
    SearchInput,
    SelectInput,
    TopToolbar,
    useGetIdentity,
    useRefresh,
    useGetList,
    Form,
    required,
    useListContext,
    RecordContextProvider,
    EditButton,
    Pagination,
    SimpleListLoading,
    ReferenceField,
    TextField,
    SimpleList,
    Loading,
    InfiniteList,
    ReferenceInput,
    AutocompleteInput,
    ReferenceArrayInput,
    Datagrid,
    FunctionField,
    BulkDeleteButton, 
    BulkExportButton, 
    BulkUpdateButton,
    BulkActionsToolbar,
    useDataProvider,
    useNotify,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router';
import { List as MUIList,ListItem, ListItemText, Box, Dialog, DialogContent, Typography, Divider, Link as MUILink, IconButton, useMediaQuery} from '@mui/material';
import { DealListContent } from './DealListContent';
import { RequestCreate } from './RequestCreate';
import { RequestEdit } from './RequestEdit';
import { OnlyMineInput } from './OnlyMineInput';
import { typeChoices, getStatusColor } from './types';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import { CallButton} from '../calls/CallButton';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { endpoint } from '../App';
import axios from 'axios';

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: requests,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

  const handleClick = () => {

    /*const fieldOrder = [
      'number',
      'name',
      'manager_name',
      'status',
      'id',
      'funnel_id',
      'stage'
    ];*/

  /*const reorderedDataToExport = requests.map((request) => {
    const reorderedAccount = { };
     fieldOrder.forEach((field) => {
         reorderedAccount[field] = Array.isArray(request[field]) ? request[field].join(', ') : request[field];
     });

    // Flatten the rest of the object
    Object.keys(reorderedAccount).forEach((field) => {
        if (Array.isArray(reorderedAccount[field])) {
            reorderedAccount[field] = reorderedAccount[field].join(', ');
        }
    });

    return reorderedAccount;
});*/

    const ws = XLSX.utils.json_to_sheet(requests);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
    XLSX.writeFile(wb, "Заявки - Выгрузка из Владелец CRM.xlsx");
  };

  return (
    <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />} sx={{ marginLeft: 2 }}>
      Выгрузить в Excel
    </Button>
  );
};

const RequestGroupEdit = () => {
    const { identity } = useGetIdentity();
    const location = useLocation();
    const refresh = useRefresh();
    const notify = useNotify();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const dataProvider = useDataProvider();
    const [currentFunnel, setCurrentFunnel] = useState(/*{"id": "123", "name": "fallback", "stages": []}*/);
    const [currentManagerFilter, setCurrentManagerFilter] = useState();
    const [currentStageFilter, setCurrentStageFilter] = useState();
    var filtersObject = {manager_account_id: '', stage: ''};
    const views = [{id: 1, name: 'Канбан'}, {id: 2, name: 'Список'}];
    const [currentView, setCurrentView] = useState(views[0]);
    const listContentRef = useRef();
    const filters = [
        {id: 1, name: 'Обычное', order: 'DESC', field: 'updated_at'}, 
        {id: 2, name: 'С конца', order: 'ASC', field: 'updated_at'},
    ];
    const [currentFilter, setCurrentFilter] = useState(filters[0]);

    // Переменные для изменения
	const [selectedManager, setSelectedManager] = useState();
    const [funnelToChange, setFunnelToChange] = useState();
    const [stageToChange, setStageToChange] = useState();

    const [selectedManager1, setSelectedManager1] = useState();
    const [selectedManager2, setSelectedManager2] = useState();

    const handleFilterChange = (value) => {
        setCurrentFilter(filters.find((item) => item.id === value.target.value));
        if (listContentRef.current) {
            listContentRef.current.setSort1(filters.find((item) => item.id === value.target.value).field, filters.find((item) => item.id === value.target.value).order);
        }
    };

    const handleSelectedStageChange = (event) => {
        setCurrentStageFilter(event.target.value);
        localStorage.setItem('currentStageFilter', event.target.value);
        filtersObject.stage = event.target.value;
        filtersObject.manager_account_id = currentManagerFilter;
        if (listContentRef.current) {
            listContentRef.current.setListFilter(filtersObject);
        }
    };

    const handleClose = () => {
        refresh();
    };

    const { data: funnels,  isLoading: isLoadingFunnels } = useGetList(
        'funnels',
        { 
            pagination: { page: 1, perPage: 5000 },
            sort: { field: 'name', order: 'ASC' },
        }
    );

    /*useEffect(() => {
      const storedViewId = localStorage.getItem('currentViewId');
      if(storedViewId){
        setCurrentView(views[storedViewId-1]);
      }
      const storedFunnelId = localStorage.getItem('currentFunnelId');
      if (funnels && storedFunnelId) {
        const selectedFunnel = funnels.find((item) => item.id === storedFunnelId);
        setCurrentFunnel(selectedFunnel || funnels[0]);
      } else if (funnels && funnels.length > 0) {
        setCurrentFunnel(funnels[0]);
      }
    }, [funnels]);*/


    useEffect(() => {
      if (currentFunnel) {
        localStorage.setItem('currentFunnelId', currentFunnel.id);
      }
    }, [currentFunnel]);
    useEffect(() => {
      const storedViewId = localStorage.getItem('currentViewId');
      if(storedViewId){
        setCurrentView(views[storedViewId-1]);
      }
    }, [currentView]);

    useEffect(() => {
        const storedManagerFilter= localStorage.getItem('currentManagerFilter');
        if(storedManagerFilter){
            setCurrentManagerFilter(storedManagerFilter);
            filtersObject.manager_account_id = storedManagerFilter;
            filtersObject.stage = currentStageFilter;
            if (listContentRef.current) {
                listContentRef.current.setListFilter(filtersObject);
            }
        }
        if(storedManagerFilter == ""){
            setCurrentManagerFilter("");
            filtersObject.manager_account_id = "";
            filtersObject.stage = currentStageFilter;
            if (listContentRef.current) {
                listContentRef.current.setListFilter(filtersObject);
            }
        }
    }, [currentManagerFilter]);

    useEffect(() => {
        const storedStageFilter= localStorage.getItem('currentStageFilter');
        if(storedStageFilter){
            setCurrentStageFilter(storedStageFilter);
            filtersObject.stage = storedStageFilter;
            filtersObject.manager_account_id = currentManagerFilter;
            if (listContentRef.current) {
                listContentRef.current.setListFilter(filtersObject);
            }
        }
        if(storedStageFilter == ""){
            setCurrentStageFilter("");
            filtersObject.stage = "";
            filtersObject.manager_account_id = currentManagerFilter;
            if (listContentRef.current) {
                listContentRef.current.setListFilter(filtersObject);
            }
        }
    }, [currentStageFilter]);


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const funnel_id = queryParams.get('funnel_id');
        if(funnels && funnel_id){
            setCurrentFunnel(funnels.find((item) => item.id === funnel_id));
        }
        if(funnels && !funnel_id){
            const storedViewId = localStorage.getItem('currentViewId');
            if(storedViewId){
              setCurrentView(views[storedViewId-1]);
            }
            const storedFunnelId = localStorage.getItem('currentFunnelId');
            if (funnels && storedFunnelId) {
              const selectedFunnel = funnels.find((item) => item.id === storedFunnelId);
              setCurrentFunnel(selectedFunnel || funnels[0]);
            } else if (funnels && funnels.length > 0) {
              setCurrentFunnel(funnels[0]);
            }
        }
    }, [funnels, location.search]);

    if(isLoadingFunnels || !currentFunnel || !currentFunnel.stages || !identity) return null;

    const handleSelectedFunnelChange = (event) => {
         console.log('Selected value:', event.target.value);
         setCurrentFunnel(funnels.find((item) => item.id === event.target.value));
         setCurrentStageFilter();
    };

    const handleViewChange = (value) => {
         console.log('Selected value:', value);
         setCurrentView(views.find((item) => item.id === value));
         localStorage.setItem('currentViewId', value);
         if (listContentRef.current) {
            listContentRef.current.setListFilter(filtersObject);
        }
    };

    const handleSelectedManagerFilterChange = (value) => {
        //setCurrentFilter(filters.find((item) => item.id === value.target.value));
        setCurrentManagerFilter(value);
        localStorage.setItem('currentManagerFilter', value);
        filtersObject.manager_account_id = value;
        filtersObject.stage = currentStageFilter;
        if (listContentRef.current) {
            listContentRef.current.setListFilter(filtersObject);
        }
    };

    // Изменения
    const handleFunnelChange = async(id) => {
		if(id){
			var recordFromDatabase = await dataProvider.getOne('funnels', { id });
			setFunnelToChange(recordFromDatabase.data);
            setStageToChange();
		}else{
            setStageToChange();
			setFunnelToChange();
		}
	};

    const handleStageChange = async(event) => {
		setStageToChange(event.target.value);
	};

    const handleManagerChange = async(id) => {
		if(id){
			var recordFromDatabase = await dataProvider.getOne('sales', { id });
			setSelectedManager(recordFromDatabase.data);
		}else{
			setSelectedManager();
		}
	};

    const handleManagerChange1 = async(id) => {
		if(id){
			var recordFromDatabase = await dataProvider.getOne('sales', { id });
			setSelectedManager1(recordFromDatabase.data);
		}else{
			setSelectedManager1();
		}
	};

    const handleManagerChange2 = async(id) => {
		if(id){
			var recordFromDatabase = await dataProvider.getOne('sales', { id });
			setSelectedManager2(recordFromDatabase.data);
		}else{
			setSelectedManager2();
		}
	};

    const transferLeads = async() => {
        console.log(selectedManager1.first_name + " " + selectedManager2.first_name);
        try {
            const token = localStorage.getItem('token'); // Get token from localStorage
            const id = localStorage.getItem('id'); // Get id from localStorage

            const response = await axios.post(
                `${endpoint}/change_manager_all`,
                {
                    manager1: selectedManager1,
                    manager2: selectedManager2,
                }, 
                {
                  headers: {
                    Authorization: `${token}` 
                  },
                }
              );
           
            console.log(new Date().toISOString() + " " + JSON.stringify(response.data));
            notify('Менеджер успешно изменён для всех заявок!', { type: 'success' });
            window.location.reload();
          } catch (error) {
            console.error('Fetch failed:', error);
            notify('Ошибка изменения менеджера, проверьте данные и попробуйте ещё раз', { type: 'error' });
          }
    }
/*
лиды без задач
лиды с просроченными от самой старой по времени
новые
 */
    return (
        <>
            <Box display="flex" flexDirection="column" sx={{ marginTop: '1rem' }}>

            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center' }}>

            <Typography variant="h4" mr={2} >Перенос всех заявок с: </Typography>


            <Box sx={{ marginRight: '1rem' }}>
            <Form className="tasks-views">
            <ReferenceInput source="manager_account_id_1" reference="sales" filter={{ 'sales_id_for_requests': localStorage.getItem('id') }} >
                <AutocompleteInput sx={{ minWidth: '15rem' }} label="Менеджер 1" onChange={handleManagerChange1} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} />
            </ReferenceInput>
            </Form>
            </Box>

            <Typography variant="h4" mr={2} >на: </Typography>

            <Box sx={{ marginRight: '2rem' }}>
            <Form className="tasks-views">
            <ReferenceInput source="manager_account_id_2" reference="sales" filter={{ 'sales_id_for_requests': localStorage.getItem('id') }}>
                <AutocompleteInput sx={{ minWidth: '15rem' }} label="Менеджер 2" onChange={handleManagerChange2} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} />
            </ReferenceInput>
            </Form>
            </Box>

            <Button variant='contained' size="small" onClick={transferLeads}>Перенести заявки</Button>

            </Box>

            <Divider sx={{ borderBottomWidth: '0.25rem', marginTop: '1rem', marginBottom: '1rem' }} />

            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center' }}>

            <Typography variant="h4" mr={2} >Меняем на: </Typography>

            <Box sx={{ marginRight: '2rem' }}>
            <Form className='filterForm'>
               <ReferenceInput source="funnel_id" reference="funnels">
                    <AutocompleteInput label="Воронка" optionText="name" fullWidth onChange={handleFunnelChange} validate={required()} />
                </ReferenceInput>
            </Form>  
            </Box>
            
            <Box sx={{ marginRight: '2rem' }}>
            <Form className="tasks-views">
            {funnelToChange?.stages ? <SelectInput
                    source="stage"
                    label="Этап"
                    choices={funnelToChange.stages}
                    fullWidth
                    onChange={handleStageChange}
                    validate={required()}
                /> : null}
            </Form>
            </Box>

            {localStorage.getItem('role_id') <= 3 ?
            <Form className="tasks-views">
              <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'sales_id_for_requests': localStorage.getItem('id') }}>
                  <AutocompleteInput label="Менеджер" defaultValue={currentManagerFilter} onChange={handleManagerChange} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} />
              </ReferenceInput>
            </Form> : null}
            </Box>

            <Divider sx={{ borderBottomWidth: '0.25rem', marginTop: '1rem', marginBottom: '1rem' }} />

            <Typography variant="h6" mr={2} >Что будет изменено: </Typography>
            {funnelToChange && stageToChange ? <Typography variant="subtitle1" mr={2} >Воронка на: {funnelToChange.name}</Typography> : null}
            {funnelToChange && stageToChange ? <Typography variant="subtitle1" mr={2} >Этап на: {funnels.find((item) => item.id === funnelToChange.id).stages.find((item) => item.id === stageToChange)?.name}</Typography> : null}
            {selectedManager ? <Typography variant="subtitle1" mr={2} >Менеджер на: {`${selectedManager.first_name} ${selectedManager.last_name}`}</Typography> : null}

            <Divider sx={{ borderBottomWidth: '0.25rem', marginTop: '1rem', marginBottom: '1rem' }} />

            <Typography variant="h4" mr={2} >Выбираем, что менять: </Typography>

            <List
            resource='requests'
            actions={<RequestsActions 
                handleViewChange={handleViewChange} 
                isSmall={isSmall} 
                handleSelectedManagerFilterChange={handleSelectedManagerFilterChange}
                currentManagerFilter={currentManagerFilter} 
                filters={filters} 
                currentFilter={currentFilter} 
                handleFilterChange={handleFilterChange}
                currentFunnel={currentFunnel}
                onSelectedFunnelChange={handleSelectedFunnelChange}
                onSelectedStageChange={handleSelectedStageChange}
                currentView={currentView.id}
                funnels={funnels}
                />}
            perPage={50}
            filter={{'funnel_id': currentFunnel.id, 'sales_id_for_requests': localStorage.getItem('id')}} 
            filters={/*localStorage.getItem('role_id') <= 3 ? requestsFilters :*/ requestsFiltersForManagers}
            sort={{ field: 'updated_at', order: 'DESC' }}
            pagination={<Pagination rowsPerPageOptions={[50, 100]} />}
            >
                <RequestListContent funnels={funnels} funnelId={currentFunnel.id} ref={listContentRef} funnel_id_to_change={funnelToChange?.id ? funnelToChange.id : ''} stage_to_change={stageToChange} manager_to_change={selectedManager} />
            </List></Box>
        </>
    );
};

const requestsFilters = [
    <SearchInput source="q" alwaysOn />,
    <OnlyMineInput alwaysOn />,
    <SelectInput source="status" choices={typeChoices} />,
];
const requestsFiltersForManagers = [
     <SearchInput source="q" alwaysOn />,
     <SelectInput source="status" choices={typeChoices} />,
];

const RequestsActions = ({handleViewChange, isSmall, handleSelectedManagerFilterChange, currentManagerFilter, filters, currentFilter, handleFilterChange, currentFunnel, onSelectedFunnelChange, onSelectedStageChange, currentView, funnels }) => {
    return (
        <TopToolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            {/*localStorage.getItem('role_id') <= 2 ? <FilterButton /> : null*/}

            <Form className='filterForm'>
            <SelectInput label="Отображение" source="view" choices={filters} defaultValue={currentFilter.id} onChange={handleFilterChange} validate={required()} helperText={null} />
            </Form>  

            <Form className='filterForm'>
                <SelectInput choices={funnels} source="funnel_id" label="Воронка" optionText="name" fullWidth defaultValue={currentFunnel.id} onChange={onSelectedFunnelChange} validate={required()} />
            </Form>  

            <Form className="tasks-views">
              <SelectInput choices={currentFunnel.stages} optionText="name" optionValue="id" source="stage" label="Этап" onChange={onSelectedStageChange}/>
            </Form>

            {localStorage.getItem('role_id') <= 3 ?
            <Form className="tasks-views">
              <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'sales_id_for_requests': localStorage.getItem('id') }}>
                  <AutocompleteInput label="Менеджер" defaultValue={currentManagerFilter} onChange={handleSelectedManagerFilterChange} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} />
              </ReferenceInput>
            </Form> : null}

            {/*localStorage.getItem('role_id') < 2 ? <CustomExportButton /> : null*/}
        </TopToolbar>
    );
};

const BulkActionButtons = ({props}) => {

    if(props.manager_to_change && props.funnel_id_to_change && props.stage_to_change){
    return (
    <Fragment>
        <BulkUpdateButton label="Применить изменения" 
            confirmTitle="Уверены, что хотите совершить массовое изменение?"
            confirmContent="Проверьте, что меняете, если нет."
            mutationMode="optimistic"
            data={{  
                ap_id: localStorage.getItem('id'),
                ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                funnel_id: props.funnel_id_to_change,
                stage: props.stage_to_change,
                manager_account_id: props.manager_to_change.id,
                manager_name: `${props.manager_to_change.first_name} ${props.manager_to_change.last_name}`
             }} 
        />
    </Fragment>
    );
    }

    if(props.funnel_id_to_change && props.stage_to_change){
        return (
        <Fragment>
            <BulkUpdateButton label="Применить изменения"
                confirmTitle="Уверены, что хотите совершить массовое изменение?"
                confirmContent="Проверьте, что меняете, если нет."
                mutationMode="optimistic" 
                data={{  
                    ap_id: localStorage.getItem('id'),
                    ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                    funnel_id: props.funnel_id_to_change,
                    stage: props.stage_to_change
                 }} 
            />
        </Fragment>
        );
    }

    if(props.manager_to_change){
        return (
        <Fragment>
            <BulkUpdateButton label="Применить изменения"
                confirmTitle="Уверены, что хотите совершить массовое изменение?"
                confirmContent="Проверьте, что меняете, если нет."
                mutationMode="optimistic" 
                data={{  
                    ap_id: localStorage.getItem('id'),
                    ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                    manager_account_id: props.manager_to_change.id,
                    manager_name: `${props.manager_to_change.first_name} ${props.manager_to_change.last_name}`
                 }} 
            />
            
        </Fragment>
        );
    }
    
    return (
        <Fragment>
          
        </Fragment>
    );

}

const RequestListContent = forwardRef(( props, ref) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {
        data: requests,
        isLoading, isFetching,
        onToggleItem,
        selectedIds,
        setFilters,
        setSort
    } = useListContext();

    const setListFilter = (filtersObject) => {
        setFilters(filtersObject, false);
    };

    useImperativeHandle(ref, () => ({
        setListFilter: (filtersObject) => setListFilter(filtersObject),
        setSort1: (field1, order1) => setSort1(field1, order1),
    }));

    const setSort1 = (field1, order1) => {
        setSort({ field: field1, order: order1 });
    };

    if (isLoading || isFetching) {
        return <Loading />
    }
    const now = Date.now();

    return (
        <>
        <Datagrid optimized bulkActionButtons={<BulkActionButtons props={props}/>}>
            <TextField source="manager_name" label="Менеджер" />
            <FunctionField
                source="funnel_id"
                label="Воронка"
                render={record => props.funnels.find((item) => item.id === record.funnel_id)?.name || ''}
            />
            <FunctionField
                source="stage"
                label="Этап"
                render={record => props.funnels.find((item) => item.id === record.funnel_id).stages.find((item) => item.id === record.stage)?.name || ''}
            />
            <FunctionField source="name" label="Заявка" render={record => `${record.name} ${record.phone}`}/>
            <FunctionField 
                source="updated_at" 
                label="Обновлено"
                render={record => {
                    if(record.updated_at){
                        return new Date(record.updated_at).toLocaleString('ru-RU');
                    }

                    return new Date(record.created_at).toLocaleString('ru-RU');
                }}
            />
        </Datagrid>
        </>
    );
});

            {/*<ListItemText
                                primary={<Typography sx={{ 
                                    border: `1.5px solid ${props.funnels.find((item) => item.id === request.funnel_id).stages.find((item) => item.id === request.stage)?.color}`,
                                    borderRadius: '0.5rem',
                                    padding: '0 0.5rem',
                                    width: 'fit-content'
                                }}>{props.funnels.find((item) => item.id === request.funnel_id).stages.find((item) => item.id === request.stage)?.name || ''}</Typography>}
                                secondary={props.funnels.find((item) => item.id === request.funnel_id)?.name || ''}
                            />*/}

export default RequestGroupEdit;
