import { FcBriefcase } from "react-icons/fc";
import { FcCallback } from "react-icons/fc";
import { FcDocument } from "react-icons/fc";
import { FcPhoneAndroid } from "react-icons/fc";

export const types = [
    {id: 'task', name: 'Задача'},
    {id: 'comment', name: 'Комментарий'}
];

export const GetTaskIcon = (type) => {
    if(type == 'Звонок'){
        return (<FcCallback />);
    }
    if(type == 'Встреча'){
        return (<FcBriefcase />);
    }
    if(type == 'Отправить документ'){
        return (<FcDocument />);
    }
    if(type == 'Написать'){
        return (<FcPhoneAndroid />);
    }
    
    return null;
}